var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", class: { loading: _vm.model.pageLoading } },
    [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorDashboard" } } },
                [_vm._v("ダッシュボード")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("router-link", { attrs: { to: { name: "CreatorChannel" } } }, [
                _vm._v("サイト"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorChannelPages" } } },
                [_vm._v("フリーページ")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("span", [_vm._v("フリーページの編集")]),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("URL")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.url.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.url.hidden },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("span", [
            _vm._v(
              "https://" +
                _vm._s(_vm.domain) +
                "/" +
                _vm._s(_vm.model.channelId) +
                "/"
            ),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.url.value,
                expression: "model.url.value",
              },
            ],
            class: { error: _vm.model.url.isError },
            attrs: { type: "text" },
            domProps: { value: _vm.model.url.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("url")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model.url, "value", $event.target.value)
                },
                function ($event) {
                  return _vm.validationCheck("url", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.url.isError },
            },
            _vm._l(_vm.model.url.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.url.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("タイトル")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.title.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.title.hidden },
              },
              [_vm._m(1)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.title.value,
                expression: "model.title.value",
              },
            ],
            staticClass: "width-600",
            class: { error: _vm.model.title.isError },
            attrs: { type: "text" },
            domProps: { value: _vm.model.title.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("title")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model.title, "value", $event.target.value)
                },
                function ($event) {
                  return _vm.validationCheck("title", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.title.isError },
            },
            _vm._l(_vm.model.title.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.title.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("ボディー")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.body.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.body.hidden },
              },
              [_vm._m(2)]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c("ckeditor", {
              class: { error: _vm.model.body.isError },
              attrs: { editor: _vm.editor, config: _vm.editorConfig },
              on: {
                change: function ($event) {
                  return _vm.validationCheck("body")
                },
                input: function ($event) {
                  return _vm.validationCheck("body", true)
                },
              },
              model: {
                value: _vm.model.body.value,
                callback: function ($$v) {
                  _vm.$set(_vm.model.body, "value", $$v)
                },
                expression: "model.body.value",
              },
            }),
            _c(
              "div",
              {
                staticClass: "errors",
                class: { hidden: !_vm.model.body.isError },
              },
              _vm._l(_vm.model.body.errors, function (item1) {
                return _c(
                  "div",
                  {
                    key: _vm.model.body.errors.indexOf(item1),
                    staticClass: "error",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("fa-icon", {
                          attrs: { icon: "exclamation-circle" },
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v(_vm._s(item1.message))]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c("hr"),
      _c("div", { staticClass: "bottom" }, [
        _vm.model.saveButtonCompleted
          ? _c("div", { staticClass: "saveButtonCompleted" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("フリーページ設定を変更しました")]),
            ])
          : _vm._e(),
        !_vm.validationCheck("all", true, true)
          ? _c("div", { staticClass: "saveButtonValidationError" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                1
              ),
              _c("span", [_vm._v("不備のある項目が存在します")]),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            attrs: {
              disabled:
                !_vm.model.saveButtonActive ||
                !_vm.validationCheck("all", true, true),
            },
            on: { click: _vm.save },
          },
          [
            _vm.model.saveButtonLoading
              ? _c("div", { staticClass: "spinner bubble" })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("fa-icon", { attrs: { icon: "save" } })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("span", [_vm._v("フリーページ設定を適用")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }