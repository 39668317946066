import { render, staticRenderFns } from "./CreatorChannelPageEdit.vue?vue&type=template&id=98d08476&scoped=true&"
import script from "./CreatorChannelPageEdit.ts?vue&type=script&lang=ts&"
export * from "./CreatorChannelPageEdit.ts?vue&type=script&lang=ts&"
import style0 from "./CreatorChannelPageEdit.vue?vue&type=style&index=0&id=98d08476&lang=scss&scoped=true&"
import style1 from "./CreatorChannelPageEdit.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98d08476",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\amano\\Desktop\\apps\\remdia\\frontend\\creator\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98d08476')) {
      api.createRecord('98d08476', component.options)
    } else {
      api.reload('98d08476', component.options)
    }
    module.hot.accept("./CreatorChannelPageEdit.vue?vue&type=template&id=98d08476&scoped=true&", function () {
      api.rerender('98d08476', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CreatorChannelPageEdit/CreatorChannelPageEdit.vue"
export default component.exports